import { FC } from "react";
import { myAdsCardProps } from "../../model/types";
import styles from "../../ui/index.module.css";

import { SecondTitle } from "../../../../shared/secondTitle/SecondTitle";
import { Text } from "../../../../shared/Text/Text";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteAdvertMutation } from "../../../../app/redux/product/apiProducts";
import { formatDate } from "../../../../app/helpers/functions";

import { MdInsertPhoto } from "react-icons/md";
import editIcon from "../../../../assets/icons/edit_icon.svg";

const MyAdsCard: FC<myAdsCardProps> = ({ item }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteAdvert] = useDeleteAdvertMutation();

  const archiveItem = async () => {
    await deleteAdvert({ listing_id: item?.listing_id });
    navigate("/account/myarchivedads");
    window.location.reload();
  };

  return (
    <div
      onClick={() => navigate(`/details/${item?.listing_id}`)}
      className={styles.card}
    >
      {item?.used_listing_pictures[0]?.picture_url ? (
        <img
          src={item?.used_listing_pictures[0]?.picture_url}
          className={styles.images}
        />
      ) : (
        <div className={styles.not_img}>
          <MdInsertPhoto fontSize={50} fill="gray" />
        </div>
      )}

      <div className={styles.card_content}>
        <ul>
          <li
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/account/me/advert/${item.listing_id}`);
            }}
          >
            Edit
          </li>
          {location.pathname == "/account/myarchivedads" ? null : (
            <li
              onClick={(e) => {
                e.stopPropagation();
                archiveItem();
              }}
            >
              Archive
            </li>
          )}
        </ul>
        <img
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/account/me/advert/${item.listing_id}`);
          }}
          src={editIcon}
          alt="edit icon"
          className={styles.edit_icon}
        />

        <div className={styles.card_content_info}>
          {/* <SecondTitle weight="500" fz="24px">
            apartment in the San Blas area
          </SecondTitle> */}
          <Text style={{ marginBottom: "10px" }}>
            {item?.district}, {item?.city}, {item?.country}
          </Text>
          <div className={styles.card_content_text}>
            <p>{item?.room} room</p>
            <div className={styles.date}>
              <Text>
                {formatDate(item?.date_from)} — {formatDate(item?.date_to)}
              </Text>
            </div>
          </div>
          <Text fw="500" fz="20px">
            {item?.price}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default MyAdsCard;
